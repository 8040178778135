<div class="container">
  <mat-checkbox
    class="is-continue"
    *ngIf="node.conditions.length > 1"
    color="primary"
    [(ngModel)]="node.isContinue"
    i18n
  >
    <ng-container *ngIf="node.isContinue; else onlyBlock"
      >多選模式：執行所有達成的條件</ng-container
    >
    <ng-template #onlyBlock>擇一模式：僅執行第一個達成的條件</ng-template>
  </mat-checkbox>

  <mat-accordion class="fw list" [multi]="true">
    <mat-expansion-panel
      *ngFor="let condition of node.conditions; let index = index"
      [id]="'condition' + index"
      [expanded]="true"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="color" [style.background-color]="condition.color"></div>
          {{ condition.name }}
        </mat-panel-title>

        <mat-panel-description>
          {{ condition.type | switchType }}
        </mat-panel-description>
      </mat-expansion-panel-header>

      <ng-container
        [ngTemplateOutlet]="conditionEditor"
        [ngTemplateOutletContext]="{ condition: condition, i: index }"
      >
      </ng-container>

      <mat-action-row>
        <div class="color posr fl">
          <button
            mat-button
            class="color-picker-trigger"
            (click)="showColorPicker(colorPickerTrigger._elementRef, index)"
            #colorPickerTrigger
            matTooltip="更換顏色"
            i18n-matTooltip="更換顏色"
            [style.background-color]="condition.color"
          ></button>
        </div>

        <button
          mat-icon-button
          (click)="setOrderCondition(index, -1)"
          matTooltip="條件優先序上移"
          i18n-matTooltip="條件優先序上移"
        >
          <mat-icon>arrow_upward</mat-icon>
        </button>

        <button
          mat-icon-button
          (click)="setOrderCondition(index, 1)"
          matTooltip="條件優先序下移"
          i18n-matTooltip="條件優先序下移"
        >
          <mat-icon>arrow_downward</mat-icon>
        </button>

        <button
          mat-icon-button
          (click)="deleteCondition(index)"
          matTooltip="刪除條件"
          i18n-matTooltip="刪除條件"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>

  <p class="empty" *ngIf="node.conditions.length === 0" i18n>
    目前沒有任何決策條件，點擊下方的按鈕新增一個吧！
  </p>
</div>

<ng-template #conditionEditor let-condition="condition" let-i="i">
  <div class="fw condition-base" fxLayout="row" fxLayoutGap="20px">
    <mat-form-field class="name" fxFlex="3 0 0" legacyAppearance>
      <mat-label i18n>條件名稱</mat-label>
      <input matInput [(ngModel)]="condition.name" />
    </mat-form-field>
    <mat-form-field class="type" fxFlex="2 0 0" legacyAppearance>
      <mat-label i18n>條件類型</mat-label>
      <mat-select [(ngModel)]="condition.type">
        <mat-option *ngFor="let type of switchTypes" [value]="type">
          {{ type | switchType }}
          <ng-container *ngIf="type === 'FILTER_IG_POST'">
            <img
              src="./assets/beta.png"
              class="align-middle w-[40px] h-[18px] ml-1"
              alt=""
            />
          </ng-container>
        </mat-option>
      </mat-select>
      <mat-hint *ngIf="condition.type === 'ELSE'" align="start" i18n
        >此條件必定成立，多選模式請謹慎使用</mat-hint
      >
    </mat-form-field>
  </div>
  <div class="fw" [ngSwitch]="condition.type">
    <ng-template [ngSwitchCase]="'TEXT'">
      <ng-container
        [ngTemplateOutlet]="condition_TEXT"
        [ngTemplateOutletContext]="{ condition: condition, i: i }"
      >
      </ng-container>
    </ng-template>
    <ng-template [ngSwitchCase]="'SOURCE_CODE'">
      <ng-container
        [ngTemplateOutlet]="condition_SOURCE_CODE"
        [ngTemplateOutletContext]="{ condition: condition }"
      >
      </ng-container>
    </ng-template>
    <ng-template [ngSwitchCase]="'FILTER_POST'">
      <ng-container
        [ngTemplateOutlet]="condition_FILTER_POST"
        [ngTemplateOutletContext]="{ condition: condition, i: i }"
      >
      </ng-container>
    </ng-template>
    <ng-template [ngSwitchCase]="'FILTER_IG_POST'">
      <ng-container
        [ngTemplateOutlet]="condition_FILTER_IG_POST"
        [ngTemplateOutletContext]="{ condition: condition, i: i }"
      >
      </ng-container>
    </ng-template>
    <ng-template [ngSwitchCase]="'KEYWORD'">
      <ng-container
        [ngTemplateOutlet]="condition_KEYWORD"
        [ngTemplateOutletContext]="{ condition: condition, i: i }"
      >
      </ng-container>
    </ng-template>
    <ng-template [ngSwitchCase]="'DATETIME'">
      <ng-container
        [ngTemplateOutlet]="condition_DATETIME"
        [ngTemplateOutletContext]="{ condition: condition, i: i }"
      ></ng-container>
    </ng-template>
    <ng-template [ngSwitchCase]="'HAS_TAG'">
      <ng-container
        [ngTemplateOutlet]="condition_HAS_TAG"
        [ngTemplateOutletContext]="{ condition: condition, i: i }"
      >
      </ng-container>
    </ng-template>
    <ng-template [ngSwitchCase]="'HAS_PROPERTY'">
      <ng-container
        [ngTemplateOutlet]="condition_HAS_PROPERTY"
        [ngTemplateOutletContext]="{ condition: condition, i: i }"
      >
      </ng-container>
    </ng-template>
    <ng-template [ngSwitchCase]="'FILTER_POST_HASHTAG'">
      <ng-container
        [ngTemplateOutlet]="condition_FILTER_POST_HASHTAG"
        [ngTemplateOutletContext]="{ condition: condition, i: i }"
      >
      </ng-container>
    </ng-template>
    <ng-template [ngSwitchCase]="'FILTER_PROFILE_ID'">
      <ng-container
        [ngTemplateOutlet]="condition_FILTER_PROFILE_ID"
        [ngTemplateOutletContext]="{ condition: condition, i: i }"
      >
      </ng-container>
    </ng-template>
    <ng-template [ngSwitchCase]="'FILTER_PROFILE_TYPE'">
      <ng-container
        [ngTemplateOutlet]="condition_FILTER_PROFILE_TYPE"
        [ngTemplateOutletContext]="{ condition: condition, i: i }"
      >
      </ng-container>
    </ng-template>
    <ng-template [ngSwitchCase]="'FILTER_BOT'">
      <ng-container
        [ngTemplateOutlet]="condition_FILTER_BOT"
        [ngTemplateOutletContext]="{ condition: condition, i: i }"
      >
      </ng-container>
    </ng-template>
    <ng-template [ngSwitchCase]="'FILTER_PLATFORM'">
      <ng-container
        [ngTemplateOutlet]="condition_FILTER_PLATFORM"
        [ngTemplateOutletContext]="{ condition: condition, i: i }"
      >
      </ng-container>
    </ng-template>
    <ng-template [ngSwitchCase]="'LINGTELLI'">
      <ng-container
        [ngTemplateOutlet]="condition_LINGTELLI"
        [ngTemplateOutletContext]="{ condition: condition, i: i }"
      >
      </ng-container>
    </ng-template>

    <ng-container *ngSwitchCase="'CHECK_EXCHANGE'">
      <div fxLayout="row" fxLayoutGap="15px">
        <programmable dataType="number">
          <mat-form-field legacyAppearance>
            <mat-label i18n>兌換活動</mat-label>
            <mat-select
              [(ngModel)]="condition.exchangeId"
              (ngModelChange)="loadExchangeMethods(condition)"
            >
              <mat-option
                *ngFor="let exchange of exchangeService.list$ | async"
                [value]="exchange.id"
              >
                {{ exchange.name }}
              </mat-option>
            </mat-select>
            <gosu-validator
              [(ngModel)]="condition.exchangeId"
              [rules]="{ required: true }"
              [offsetY]="20"
            ></gosu-validator>
          </mat-form-field>
        </programmable>

        <programmable [(value)]="condition.methodId" dataType="number">
          <mat-form-field legacyAppearance>
            <mat-label i18n>兌換辦法</mat-label>
            <mat-select [(ngModel)]="condition.methodId">
              <mat-option
                *ngFor="let method of condition.methods"
                [value]="method.id"
              >
                {{ method.name }}
              </mat-option>
            </mat-select>
            <gosu-validator
              [(ngModel)]="condition.methodId"
              [rules]="{ arrayRequired: true }"
              [offsetY]="20"
            ></gosu-validator>
          </mat-form-field>
        </programmable>

        <mat-form-field legacyAppearance>
          <mat-label i18n>指定兌換對象</mat-label>
          <input
            matInput
            [(ngModel)]="condition.targetProfileId"
            matTooltip="沒有指定時，兌換給觸發者；只能填入一個兌換對象"
            i18n-matTooltip="沒有指定時，兌換給觸發者；只能填入一個兌換對象"
          />
        </mat-form-field>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #condition_LINGTELLI let-condition="condition" let-i="i">
  <div class="group">
    <div
      class="omit question-content"
      *ngIf="condition.lingtelliAnswer"
      [title]="condition?.lingtelliDisplay"
      i18n
    >
      問題群組： {{ condition?.lingtelliDisplay }}
    </div>
    <div class="tac">
      <button
        class="open-lingtelli-dialog-button"
        mat-raised-button
        color="primary"
        (click)="openLingtelliDialog(condition)"
        i18n
      >
        選擇語意群組
      </button>
    </div>
    <gosu-validator
      [(ngModel)]="condition.lingtelliDisplay"
      [rules]="{ required: true }"
      [offsetY]="15"
    ></gosu-validator>
  </div>
</ng-template>

<ng-template #condition_DATETIME let-condition="condition" let-i="i">
  <div class="datetime">
    <mat-form-field class="selector" legacyAppearance>
      <mat-label i18n>比對類型</mat-label>
      <mat-select
        #dateType
        placeholder="比對類型"
        i18n-placeholder="比對類型"
        [(ngModel)]="condition.datetimeType"
        (ngModelChange)="
          condition.datetimeFormat =
            $event == 'time'
              ? 'HH:mm:ss'
              : $event == 'date'
                ? 'YYYY-MM-DD'
                : 'YYYY-MM-DD HH:mm:ss'
        "
      >
        <mat-option value="date" i18n>日期</mat-option>
        <mat-option value="time" i18n>時間</mat-option>
        <mat-option value="datetime" i18n>日期時間</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field legacyAppearance class="format">
      <mat-label i18n>比對格式</mat-label>
      <input
        #dateFormat
        matInput
        placeholder="比對格式"
        i18n-placeholder="比對格式"
        [(ngModel)]="condition.datetimeFormat"
      />
      <mat-hint>
        <a
          href="https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/"
          target="_blank"
          i18n
        >
          格式表
        </a>
      </mat-hint>
      <gosu-validator
        [(ngModel)]="condition.datetimeFormat"
        [rules]="{ required: true }"
        [offsetY]="38"
      ></gosu-validator>
    </mat-form-field>

    <programmable [(value)]="condition.datetime" dataType="string">
      <gosu-datetime-picker
        #datetime1
        [(value)]="condition.datetime"
        [type]="condition.datetimeType"
        [inputFormat]="dateFormat.value"
        [placeholder]="
          '比對' + (dateType.selected ? dateType.selected['viewValue'] : '日期')
        "
      ></gosu-datetime-picker>
      <gosu-validator
        [(ngModel)]="condition.datetime"
        [rules]="{ required: true }"
        [offsetY]="12"
      ></gosu-validator>
    </programmable>

    <mat-form-field class="selector" legacyAppearance>
      <mat-label i18n>檢測方式</mat-label>
      <mat-select
        placeholder="檢測方式"
        i18n-placeholder="檢測方式"
        [(ngModel)]="condition.datetimeMethod"
        (ngModelChange)="changeDatetimeMethod(condition)"
      >
        <mat-option
          *ngFor="let method of datetimeMethods"
          [value]="method.value"
        >
          {{ method.text }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <programmable
      [(value)]="condition.judgeDatetime"
      *ngIf="!condition.datetimeMethod?.includes('Now')"
      dataType="string"
    >
      <gosu-datetime-picker
        [(value)]="condition.judgeDatetime"
        [type]="condition.datetimeType"
        [inputFormat]="dateFormat.value"
        [placeholder]="
          '對照' + (dateType.selected ? dateType.selected['viewValue'] : '日期')
        "
      ></gosu-datetime-picker>
      <gosu-validator
        [(ngModel)]="condition.judgeDatetime"
        [rules]="{ required: true }"
        [offsetY]="12"
      ></gosu-validator>
    </programmable>
  </div>
</ng-template>

<ng-template #condition_FILTER_PROFILE_TYPE let-condition="condition" let-i="i">
  <div class="group">
    <mat-checkbox
      [ngModel]="condition.profileTypes.includes('User')"
      (change)="checkboxEvent(condition, 'profileTypes', 'User', $event)"
      i18n
      >好友</mat-checkbox
    >
    <mat-checkbox
      [ngModel]="condition.profileTypes.includes('Group')"
      (change)="checkboxEvent(condition, 'profileTypes', 'Group', $event)"
      i18n
      >群組</mat-checkbox
    >
    <gosu-validator
      [(ngModel)]="condition.profileTypes"
      [rules]="{ arrayRequired: true }"
      [offsetY]="20"
    ></gosu-validator>
  </div>
</ng-template>

<ng-template #condition_FILTER_BOT let-condition="condition" let-i="i">
  <div class="group">
    <mat-checkbox
      *ngFor="let bot of bots$ | async"
      [ngModel]="condition.botIds.includes(bot.id)"
      (change)="checkboxEvent(condition, 'botIds', bot.id, $event)"
      >{{ bot.name }}</mat-checkbox
    >
    <gosu-validator
      [(ngModel)]="condition.botIds"
      [rules]="{ arrayRequired: true }"
      [offsetY]="20"
    ></gosu-validator>
  </div>
</ng-template>

<ng-template #condition_FILTER_PLATFORM let-condition="condition" let-i="i">
  <div class="group">
    <mat-checkbox
      [ngModel]="condition.platforms.includes('Facebook')"
      (change)="checkboxEvent(condition, 'platforms', 'Facebook', $event)"
      >Facebook</mat-checkbox
    >
    <mat-checkbox
      [ngModel]="condition.platforms.includes('Instagram')"
      (change)="checkboxEvent(condition, 'platforms', 'Instagram', $event)"
      >Instagram</mat-checkbox
    >
    <mat-checkbox
      [ngModel]="condition.platforms.includes('LineMessagingAPI')"
      (change)="
        checkboxEvent(condition, 'platforms', 'LineMessagingAPI', $event)
      "
      >LineMessagingAPI</mat-checkbox
    >
    <mat-checkbox
      [ngModel]="condition.platforms.includes('LineModuleChannel')"
      (change)="
        checkboxEvent(condition, 'platforms', 'LineModuleChannel', $event)
      "
      >LineModuleChannel</mat-checkbox
    >
    <gosu-validator
      [(ngModel)]="condition.platforms"
      [rules]="{ arrayRequired: true }"
      [offsetY]="20"
    ></gosu-validator>
  </div>
</ng-template>

<ng-template #condition_FILTER_PROFILE_ID let-condition="condition" let-i="i">
  <mat-form-field class="fw mb-6" legacyAppearance>
    <mat-label i18n>篩選好友編號</mat-label>
    <mat-chip-grid #chipList>
      <mat-chip-row
        *ngFor="let item of condition.profileIds"
        selectable="true"
        removable="true"
        (removed)="removeItem(condition, 'profileIds', item)"
      >
        {{ item }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
      <input
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="
          addItem(condition, 'profileIds', $event, 'number')
        "
      />
    </mat-chip-grid>
    <gosu-validator
      [(ngModel)]="condition.profileIds"
      [rules]="{ arrayRequired: true }"
      [offsetY]="38"
    ></gosu-validator>
    <mat-hint align="start" i18n>
      以半形逗號分隔; 執行此節點的好友之編號存在於條件中，此條件即成立</mat-hint
    >
  </mat-form-field>
</ng-template>

<ng-template #condition_FILTER_POST_HASHTAG let-condition="condition" let-i="i">
  <mat-form-field class="fw" legacyAppearance>
    <mat-label i18n>比對 Facebook 文章中的 HashTag</mat-label>
    <mat-chip-grid #chipList>
      <mat-chip-row
        *ngFor="let item of condition.hashTags"
        selectable="true"
        removable="true"
        (removed)="removeItem(condition, 'hashTags', item)"
      >
        {{ item }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
      <input
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="addHashTag(condition, $event)"
      />
    </mat-chip-grid>
    <gosu-validator
      [(ngModel)]="condition.hashTags"
      [rules]="{ arrayRequired: true }"
      [offsetY]="20"
    ></gosu-validator>
  </mat-form-field>
</ng-template>

<ng-template #condition_TEXT let-condition="condition" let-i="i">
  <mat-form-field class="fw mb-6" legacyAppearance>
    <mat-label i18n>比對的文字</mat-label>
    <mat-chip-grid #chipList>
      <mat-chip-row
        *ngFor="let item of condition.key | split"
        selectable="true"
        removable="true"
        (removed)="removeText(condition, item)"
      >
        {{ item }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
      <input
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="addText(condition, $event)"
      />
    </mat-chip-grid>
    <mat-hint align="start" i18n>
      比對時不區分全半形及大小寫；多個文字比對時，透過半型逗號分隔，例如: hi,
      hello
    </mat-hint>
    <gosu-validator
      [(ngModel)]="condition.key"
      [rules]="{ required: true }"
      [offsetY]="38"
    ></gosu-validator>
  </mat-form-field>
</ng-template>

<ng-template #condition_SOURCE_CODE let-condition="condition">
  <ng-container *ngIf="enableMonaco$ | async; else textarea">
    <ngx-monaco-editor
      [options]="editorOptions"
      [(ngModel)]="condition.logic"
      (init)="onMonacoEditorInit()"
    >
    </ngx-monaco-editor>
  </ng-container>

  <ng-template #textarea>
    <textarea class="code-space" [(ngModel)]="condition.logic"></textarea>
  </ng-template>

  <mat-hint align="start" i18n>若邏輯結果為 true，將執行此條件</mat-hint>
  <div style="position: relative">
    <gosu-validator
      [(ngModel)]="condition.logic"
      [rules]="{ required: true }"
      [offsetY]="20"
    ></gosu-validator>
  </div>
</ng-template>

<ng-template #condition_FILTER_POST let-condition="condition" let-i="i">
  <mat-form-field
    class="fw"
    *ngIf="postNameMap$ | async; let nameMap"
    legacyAppearance
  >
    <mat-label i18n>允許的文章</mat-label>
    <mat-chip-grid #chipList>
      <mat-chip-row
        *ngFor="let postId of condition.postIds"
        selectable="true"
        removable="true"
        (removed)="removedItem('postIds', condition, postId)"
        [matTooltip]="nameMap[postId]"
      >
        {{ nameMap[postId] || postId | ensureTextLength: 21 }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
      <input
        matInput
        [matChipInputFor]="chipList"
        (click)="openFacebookPostDialog(condition)"
      />
    </mat-chip-grid>
    <gosu-validator
      [(ngModel)]="condition.postIds"
      [rules]="{ arrayRequired: true }"
      [offsetY]="20"
    ></gosu-validator>
  </mat-form-field>
</ng-template>

<ng-template #condition_KEYWORD let-condition="condition" let-i="i">
  <mat-form-field
    legacyAppearance
    class="fw"
    *ngIf="keywordNameMap$ | async; let keywordNameMap"
  >
    <mat-label i18n>關鍵字組合</mat-label>
    <mat-chip-grid #chipList>
      <mat-chip-row
        *ngFor="let keywordId of condition.keywords"
        selectable="true"
        removable="true"
        (removed)="removedItem('keywords', condition, keywordId)"
      >
        {{ keywordNameMap[keywordId] }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
      <input
        matInput
        [matTreePicker]="treePicker"
        [matChipInputFor]="chipList"
      />
    </mat-chip-grid>
    <mat-tree-picker #treePicker [dataSource]="keywordList$ | async">
      <mat-tree-picker-node
        *matTreePickerNodeDef="let node"
        (selected)="selectedItem('keywords', condition, node.id)"
      >
        <mat-checkbox [checked]="condition?.keywords?.includes(node.id)">{{
          node?.name
        }}</mat-checkbox>
      </mat-tree-picker-node>
    </mat-tree-picker>
    <gosu-validator
      [(ngModel)]="condition.keywords"
      [rules]="{ arrayRequired: true }"
      [offsetY]="38"
    ></gosu-validator>
    <mat-hint i18n>
      所選擇的關鍵字組合皆出現時，此條件將會成立，並將捕捉到的關鍵字存於
      keywords 變數中</mat-hint
    >
  </mat-form-field>
</ng-template>

<ng-template #condition_HAS_TAG let-condition="condition" let-i="i">
  <div class="fw" fxLayout="row" fxLayoutGap="20px">
    <programmable
      fxFlex="3 0 0"
      [value]="condition.tagIds"
      (valueChange)="parseTagIds($event)"
      dataType="number[]"
      *ngIf="tagMap$ | async; let tagMap"
    >
      <mat-form-field class="mb-6" fxFlex="1 1 auto" legacyAppearance>
        <mat-label i18n>篩選的標籤</mat-label>
        <mat-chip-grid #chipList>
          <mat-chip-row
            *ngFor="let tagId of condition.tagIds"
            [style.backgroundColor]="tagMap[tagId]?.color"
            selectable="true"
            removable="true"
            (removed)="removedItem('tagIds', condition, tagId)"
          >
            {{ tagMap[tagId]?.name }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip-row>
          <input
            matInput
            [matTreePicker]="treePicker"
            [matChipInputFor]="chipList"
          />
        </mat-chip-grid>
        <mat-hint align="start" i18n>
          當檢測對象擁有所選的標籤中任一標籤時，此條件即成立
        </mat-hint>

        <gosu-validator
          [(ngModel)]="condition.tagIds"
          [rules]="{ arrayRequired: true }"
          [offsetY]="38"
        ></gosu-validator>
        <gosu-validator
          [(ngModel)]="condition.tagIds"
          [rules]="{ bannedOptions: true }"
          [offsetX]="3"
          [offsetY]="40"
          [message]="noneExistTag"
          [payload]="{ bannedOptions: deletedTagNames }"
        ></gosu-validator>

        <mat-tree-picker #treePicker [dataSource]="tagList$ | async">
          <mat-tree-picker-node
            *matTreePickerNodeDef="let tag"
            (selected)="selectedItem('tagIds', condition, tag.id)"
          >
            <mat-checkbox
              [checked]="condition.tagIds.join(',').includes(tag.id)"
            >
              {{ tag.name }}
            </mat-checkbox>
          </mat-tree-picker-node>
        </mat-tree-picker>
      </mat-form-field>
    </programmable>
  </div>
</ng-template>

<ng-template #condition_HAS_PROPERTY let-condition="condition" let-i="i">
  <div class="fw" fxLayout="row" fxLayoutGap="20px">
    <mat-form-field class="type" fxFlex="1 0 0" legacyAppearance>
      <mat-label i18n>檢測對象</mat-label>
      <mat-select [(ngModel)]="condition.target">
        <mat-option *ngFor="let type of propertyTargetTypes" [value]="type">{{
          type | moduleType
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      fxFlex="2 0 0"
      *ngIf="propConfigs$ | async; let configs"
      legacyAppearance
    >
      <mat-label i18n>屬性名稱</mat-label>
      <mat-select [(ngModel)]="condition.key">
        <mat-option
          *ngFor="let config of configs[condition.target]"
          [value]="config.key"
          >{{ config.name }} | {{ config.key }}</mat-option
        >
      </mat-select>
      <gosu-validator
        [(ngModel)]="condition.key"
        [rules]="{ required: true }"
        [offsetY]="20"
      ></gosu-validator>
    </mat-form-field>

    <ng-container *ngIf="propConfigs$ | async; let configs">
      <ng-container
        *ngIf="
          selectProperty(condition.key, configs[condition.target]);
          let property
        "
      >
        <mat-form-field class="type" fxFlex="1 0 0" legacyAppearance>
          <mat-label i18n>檢測方式</mat-label>
          <mat-select [(ngModel)]="condition.operand">
            <mat-option
              *ngFor="let operand of $any(property.dataType) | operands"
              [value]="operand"
            >
              {{ operand | operandTranslate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <programmable
          [(value)]="condition.value"
          dataType="string"
          *ngIf="!['isExist', 'notExist'].includes(condition.operand)"
        >
          <mat-form-field fxFlex="3 0 0" legacyAppearance>
            <mat-label i18n>屬性值</mat-label>
            <input
              matInput
              [type]="property.dataType === 'number' ? 'number' : 'text'"
              [(ngModel)]="condition.value"
            />
            <gosu-validator
              [(ngModel)]="condition.value"
              [rules]="{ required: true }"
              [offsetY]="20"
            ></gosu-validator>
          </mat-form-field>
        </programmable>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #footer>
  <button mat-button class="add-condition-btn" (click)="addCondition()" i18n>
    <mat-icon>add</mat-icon> 新增條件
  </button>
  <button
    mat-button
    color="primary"
    class="add-condition-btn"
    (click)="dynamic.submit()"
    i18n
  >
    確定
  </button>
</ng-template>

<ng-template #condition_FILTER_IG_POST let-condition="condition" let-i="i">
  <mat-form-field class="fw" *ngIf="postNameMap$ | async; let nameMap">
    <mat-label i18n>允許的文章</mat-label>
    <mat-chip-grid #chipList>
      <mat-chip-row
        *ngFor="let postId of condition.postIds"
        selectable="true"
        removable="true"
        (removed)="removedItem('postIds', condition, postId)"
        [matTooltip]="nameMap[postId]"
      >
        {{ nameMap[postId] || postId | ensureTextLength: 21 }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
      <input
        matInput
        [matChipInputFor]="chipList"
        (click)="openInstagramPostDialog(condition)"
      />
    </mat-chip-grid>
    <gosu-validator
      [(ngModel)]="condition.postIds"
      [rules]="{ arrayRequired: true }"
      [offsetY]="20"
    ></gosu-validator>
  </mat-form-field>
</ng-template>
